import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class Logger {
  private _environment: string;
  printMessage(className: string, logLevel: string, ...params: any[]): void {
    (console as any)[logLevel](
      new Date().toLocaleString('de'),
      className,
      ...params,
    );
  }

  log(className: string, ...params: any[]): void {
    this.printMessage(className, 'log', ...params);
  }

  debug(className: string, ...params: any[]): void {
    if (this._environment === 'local' || this._environment === 'develop') {
      this.printMessage(className, 'log', ...params);
    }
  }

  error(className: string, ...params: any[]): void {
    this.printMessage(className, 'error', ...params);
  }
}
