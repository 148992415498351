/* eslint-disable @angular-eslint/component-selector */
import {
  NotificationAction,
  NotificationPinnedAction,
  NotificationReadAction,
  NotificationType,
} from '@/app/external-modules/notifications/actions/notification.action';
import {
  INotification,
  IPopupNotification,
} from '@/app/external-modules/notifications/interfaces/notification.interface';
import { getNotificationListSelect } from '@/app/external-modules/notifications/reducers/module';
import {
  DarkMode,
  DarkModes,
  ThemeService,
} from '@/app/services/theme.service';
import { WebsocketService } from '@/app/services/websocket/websocket.service';
import { environment } from '@/environments/environment';
import { Component, Input, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import * as header from '../../actions/header';
import { RequestManual } from '../../actions/help';
import { UpdateActiveLanguage } from '../../actions/language';
import { HeaderModuleConfig } from '../../header.module.config';
import { EventType, HeaderEvent } from '../../models/header-event';
import { Identity } from '../../models/identity';
import { ILanguage } from '../../models/language';
import * as fromLogin from '../../reducers/login';
import * as fromModule from '../../reducers/module';

@Component({
  selector: 'login-component',
  styleUrls: ['login.component.scss'],
  templateUrl: 'login.component.html',
})
export class LoginComponent implements OnInit {
  private readonly _websocketService = inject(WebsocketService);

  environment = environment;

  @Input()
  roles: string[] = [];

  public config = inject(HeaderModuleConfig);
  private _store: Store<fromLogin.State> = inject(Store);
  private _translateService = inject(TranslateService);
  private _themeService = inject(ThemeService);

  private _loggedIn$: Observable<boolean>;
  private _identity$: Observable<Identity>;
  private _headerEvent$: Observable<HeaderEvent>;
  private _languages$: Observable<ILanguage[]>;
  private _activeLanguage$: Observable<ILanguage>;

  private _identity: Identity;
  private _loggedIn: boolean;
  private _headerEvent: HeaderEvent;
  private _activeLanguage: ILanguage;
  private _languages: ILanguage[];
  private _notifications: INotification[] = [];
  private _newNotifications = false;

  public theme$ = this._themeService.darkMode$;

  constructor() {
    this._identity$ = this._store.select(fromModule.getIdentitySelect);
    this._identity$.subscribe((state) => (this._identity = state));
    this._loggedIn$ = this._store.select(fromModule.getLoggedInSelect);
    this._loggedIn$.subscribe((state) => (this._loggedIn = state));
    this._headerEvent$ = this._store.select(fromModule.getHeaderEventSelect);
    this._headerEvent$.subscribe((state) => (this._headerEvent = state));
    this._languages$ = this._store.select(fromModule.getLanguagesSelect);
    this._languages$.subscribe((state) => (this._languages = state));
    this._activeLanguage$ = this._store.select(
      fromModule.getActiveLanguageSelect,
    );
    this._activeLanguage$.subscribe((state) => (this._activeLanguage = state));
  }

  get identity(): Identity {
    return this._identity;
  }

  get loggedIn(): boolean {
    return this._loggedIn;
  }

  get headerEvent(): EventType {
    if (this._headerEvent) {
      return this._headerEvent.eventType;
    }
    return undefined!;
  }

  get accountUrl(): string {
    if (this._identity) {
      return this._identity.accountUrl;
    }
    return undefined!;
  }

  get name(): string {
    if (this._identity) {
      return this._identity.firstName + ' ' + this._identity.lastName;
    }
    return undefined!;
  }

  get languages() {
    return this._languages;
  }

  get activeLanguage() {
    return this._activeLanguage;
  }

  set activeLanguage(language: ILanguage) {
    this._store.dispatch(new UpdateActiveLanguage(language));
  }

  get newNotifications() {
    return this._newNotifications;
  }

  get notifications(): INotification[] {
    return this._notifications;
  }

  get darkModes() {
    return DarkModes;
  }

  ngOnInit() {
    this._store.select(getNotificationListSelect).subscribe((state) => {
      this._notifications = state?.notifications || [];
      this._newNotifications = !!state?.newNotifications;
    });
  }

  login() {
    this._store.dispatch(
      new header.EmitHeaderEvent({ eventType: EventType.Login }),
    );
  }

  logout() {
    this._store.dispatch(
      new header.EmitHeaderEvent({ eventType: EventType.Logout }),
    );
  }

  notificationClick(notification: INotification, index: number) {
    this._store.dispatch(
      new NotificationAction({
        message: notification.message,
        notificationType: NotificationType.PopUp,
        confirmable: true,
        callback: () => {
          this._store.dispatch(new NotificationReadAction(notification));
        },
      } as IPopupNotification),
    );
  }

  notificationRead(notification: INotification) {
    this._store.dispatch(new NotificationReadAction(notification));
  }

  notificationPinned(notification: INotification) {
    this._store.dispatch(new NotificationPinnedAction(notification));
  }

  requestManual() {
    this._store.dispatch(new RequestManual(new Date()));
  }

  getNotificationTitle(notification: INotification) {
    if (notification) {
      return notification.title;
    }
    return undefined!;
  }
  getNotificationText(notification: INotification) {
    if (notification) {
      return notification.message;
    }
    return undefined!;
  }

  evalLanguage(langMap: { [key: string]: string }): string {
    let ret: string = this._translateService.getDefaultLang();

    if (
      this._activeLanguage &&
      typeof langMap[this._activeLanguage.code] === 'string'
    ) {
      ret = this._activeLanguage.code;
    }

    if (typeof langMap[ret] !== 'string') {
      ret = undefined!;
    }

    return ret;
  }

  clearRoleView() {
    this._store.dispatch(
      new header.EmitHeaderEvent({ eventType: EventType.ClearRoleView }),
    );
  }

  setRoleView(role: string) {
    this._store.dispatch(
      new header.EmitHeaderEvent({
        eventType: EventType.SetRoleView,
        payload: role,
      }),
    );
  }

  showRoleViewSelection() {
    if (
      this.config.showRoleView &&
      this.loggedIn &&
      (this.hasRole('Bewatec_Admin') || this.hasRole('Super_Admin'))
    ) {
      return true;
    } else {
      return false;
    }
  }

  hasRole(role: string) {
    if (this.roles) {
      if (this.roles.indexOf(role.toLowerCase()) !== -1) {
        return true;
      }
    }
    return false;
  }

  public setTheme(theme: DarkMode) {
    this._themeService.setDarkMode(theme);
  }

  // get newNotificationsCount() {
  //   return this._notifications.filter((el) => el.isNew).length;
  // }

  testWsConnection() {
    this._websocketService.testConnectionError();
  }
}
